import { AppInfo } from "@mitel/cloudlink-sdk";
import { DashboardQueryComponent } from "../app/dashboard-query/dashboard-query.component";

export const DevRedirect: string = "https://dashboards.analytics.dev.mitel.io";
export const DevStageRedirect: string = "https://dashboards.analytics-stage.dev.mitel.io";
export const PublicRedirect: string = "https://dashboards.analytics.mitel.io";
export const PublicStageRedirect: string = "https://dashboards.analytics-stage.mitel.io";

export const environment = {
	production: false,
	cloud: "dev",
	clientId: "1f402d93d17244f7ad6f04abb8f0c927",
	appTitle: "customer-analytics-spa",
	urlDashboardConstant:
		"https://analytics.dev.api.mitel.io/2020-06-19/dashboards/embeddable?timezone=etc%2FUTC&timeFormat=%25r&dateFormat=%25Y-%25d-%25m&clientId=1f402d93d17244f7ad6f04abb8f0c927",
	urlDashboardsidenav:
		"https://analytics.dev.api.mitel.io/2020-06-19/dashboards/embeddable?timezone=etc%2FUTC&timeFormat=%25r&dateFormat=%25Y-%25d-%25m&clientId=1f402d93d17244f7ad6f04abb8f0c927",
	urlDashboard:
		"https://analytics.dev.api.mitel.io/2020-06-19/dashboards/embeddable?timezone=etc%2FUTC&timeFormat=%25r&dateFormat=%25Y-%25d-%25m&clientId=1f402d93d17244f7ad6f04abb8f0c927&language=" +
		navigator.language.substring(0, 2),
	redirectUri: "https://dashboards.analytics.dev.mitel.io", // this needs to get modified during startup
	appInfo: new AppInfo({
		correlationIdPrefix: "customer-analytics-spa",
		enableAppHeader: true
	})
};
