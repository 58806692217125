<!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
		<title>Side Navigation Bar</title>
		<style>
			.centerr {
				vertical-align: middle;
				font-size: x-large;
				color: #d3d3d3;
			}
			p {
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		</style>
	</head>
	<body style="background-color: white">
		<div *ngIf="dashboardsCollection.length != 0; then thenBlock; else elseBlock"></div>
		<ng-template #thenBlock>
			<div id="timepicker">
				<mat-form-field appearance="fill">
					<mat-label>{{ "app.enterDateRange" | translate }}</mat-label>
					<mat-date-range-input [rangePicker]="picker">
						<input
							matStartDate
							placeholder="Start date"
							[formControl]="defaultStartDate"
							(dateChange)="startDateChanged($event)"
						/>
						<input matEndDate placeholder="End date" [formControl]="defaultEndDate" (dateChange)="endDateChanged($event)" />
					</mat-date-range-input>
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
			<cl-side-nav [menuItems]="dashboardsCollection" [defaultItemId]="defaultMenuId" (itemClicked)="load($event)"> </cl-side-nav>
			<div *ngIf="initial == 0 ? load(dashboardsCollection[0].href) : false">;</div>
		</ng-template>

		<ng-template ng-template #elseBlock
			><cl-side-nav [menuItems]="dashboardsCollection"> </cl-side-nav>

			<div class="centerr">
				<p>{{ "app.noDashboards" | translate }}</p>
			</div></ng-template
		>
	</body>
</html>
