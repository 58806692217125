<div id="container">
	<cl-header appLabelName="Dashboards" [cloud]="cloudType" [useStage]="false"></cl-header>
	<ng-container *ngIf="dataDashboard; then caseA; else caseB"> </ng-container>
	<ng-template #caseA>
		<app-dashboard-query></app-dashboard-query>
	</ng-template>
	<ng-template #caseB>
		<div
			*ngIf="errmsg; else caseC"
			style="
				position: fixed;
				z-index: 999;
				overflow: show;
				margin: auto;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 50px;
				height: 50px;
			"
			mode="indeterminate"
		>
			{{ errmsg }}
		</div>
		<ng-template #caseC>
			<div
				style="
					position: fixed;
					z-index: 999;
					overflow: show;
					margin: auto;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 50px;
					height: 50px;
				"
				mode="indeterminate"
			>
				<mat-spinner></mat-spinner>
			</div>
		</ng-template>
	</ng-template>
</div>
