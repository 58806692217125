import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import * as contentEn from "../assets/i18n/en.json";
import * as contentDe from "../assets/i18n/de.json";
import * as contentFr from "../assets/i18n/fr.json";
import * as contentEs from "../assets/i18n/es.json";
import * as contentIt from "../assets/i18n/it.json";
import * as contentNl from "../assets/i18n/nl.json";
import * as contentPt from "../assets/i18n/pt_PT.json";
import * as clContentEn from "../assets/cl-console-components/i18n/en.json";
import * as clContentDe from "../assets/cl-console-components/i18n/de.json";
import * as clContentFr from "../assets/cl-console-components/i18n/fr.json";
import * as clContentEs from "../assets/cl-console-components/i18n/es.json";
import * as clContentIt from "../assets/cl-console-components/i18n/it.json";
import * as clContentNl from "../assets/cl-console-components/i18n/nl.json";
import * as clContentPt from "../assets/cl-console-components/i18n/pt_PT.json";
const TRANSLATIONS = {
	en: { ...contentEn["default"], ...clContentEn["default"] },
	de: { ...contentDe["default"], ...clContentDe["default"] },
	fr: { ...contentFr["default"], ...clContentFr["default"] },
	es: { ...contentEs["default"], ...clContentEs["default"] },
	it: { ...contentIt["default"], ...clContentIt["default"] },
	nl: { ...contentNl["default"], ...clContentNl["default"] },
	pt: { ...contentPt["default"], ...clContentPt["default"] }
};
export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return of(TRANSLATIONS[lang]);
	}
}
