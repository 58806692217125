import { Component, OnInit, OnDestroy, LOCALE_ID } from "@angular/core";
import { GetDashboardService } from "./services/get-dashboard.service";
import { ActivatedRoute, Router, Route } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { DashboardQueryComponent } from "./dashboard-query/dashboard-query.component";
import { ClHeaderComponent } from "@mitel/cloudlink-console-components";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {
	useIdentities: true;
	dataDashboard: any;
	startdateQuery = "";
	enddateQuery = "";
	public startDate = new Subject();
	public endDate = new Subject();
	currentlanguage: any;
	subscription: Subscription;
	dashboardsCollection: any;
	public dashboardsChanged = new Subject();
	cloudType = environment.cloud;
	errmsg: any;
	defaultStartDateString: any;
	defaultEndDateString: any;
	title = "Dashboards";
	constructor(
		private dashboardService: GetDashboardService,
		private clHeader: ClHeaderComponent,
		private translateService: TranslateService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		if (location.href && location.href.indexOf("?startdate=") > 0) {
			this.startdateQuery = location.href.split("?startdate=")[1].split("&")[0];
		} else if (location.href && location.href.indexOf("&startdate=") > 0) {
			this.startdateQuery = location.href.split("&startdate=")[1].split("&")[0];
		}
		if (location.href && location.href.indexOf("?enddate=") > 0) {
			this.enddateQuery = location.href.split("?enddate=")[1].split("&")[0];
		} else if (location.href && location.href.indexOf("&enddate=") > 0) {
			this.enddateQuery = location.href.split("&enddate=")[1].split("&")[0];
		}
		if (this.startdateQuery != "" && this.enddateQuery != "") {
			//When SPA is cross launched start date and end date query parameters are set as default start date and end date
			this.defaultStartDateString = this.convertDatetoString(new Date(new Date(this.startdateQuery).getTime()));
			this.defaultEndDateString = this.convertDatetoString(new Date(new Date(this.enddateQuery).getTime()));
		} else {
			//When the SPA is loaded default start date is set as currentdate-30 and default end date is current date
			this.defaultStartDateString = this.convertDatetoString(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
			this.defaultEndDateString = this.convertDatetoString(new Date(new Date().getTime()));
		}

		environment.urlDashboard =
			environment.urlDashboard + "&start_date=" + this.defaultStartDateString + "&end_date=" + this.defaultEndDateString;
		environment.urlDashboardsidenav =
			environment.urlDashboardsidenav + "&start_date=" + this.defaultStartDateString + "&end_date=" + this.defaultEndDateString;
		this.clHeader.setClientId(environment.clientId);
		this.cloudType = environment.cloud;
		//Whenever the dashboards through API call gets changed dashboardsChanges subcribers gets notified
		this.subscription = this.dashboardService.dashboardsChanged.subscribe((dashboardsCollection) => {
			this.dashboardsCollection = dashboardsCollection;
		});

		//onLangChange event gets called when the language in the language switcher in cl-sidenav gets changed
		this.translateService.onLangChange.subscribe(() => {
			this.currentlanguage = this.translateService.currentLang.substring(0, 2);
			//Url is modified and api call is made
			(environment.urlDashboard = environment.urlDashboardsidenav + "&language=" + this.currentlanguage),
				console.log(environment.urlDashboard);
			this.doApiCall();
		});

		setTimeout(() => {
			this.doApiCall();
		}, 4000);
	}
	//Api call for retrieving the user specific dashboards
	doApiCall() {
		this.dashboardService.retrieveDashboard().subscribe(
			(val) => {
				if (val !== undefined) {
					this.dataDashboard = val;
					//setDashboard will assign the retrieved dashboards to "dashboardsChanged" subject
					this.dashboardService.setDashboard(this.dataDashboard._embedded.items);
				}
			},
			(error) => (this.errmsg = error)
		);
	}

	//Converts Sat Nov 20 2021 11:32:21 GMT+0530 (India Standard Time) to 11/12/2021
	convertDatetoString(str) {
		console.log(str);
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	}

	ngOnDestroy() {
		//Unsubscribing the dashboardsChanged subscribers so that there won't be any memory leak
		this.subscription.unsubscribe();
	}
}
