import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppComponent } from "./app.component";
import { ClConsoleComponentsModule, ClHeaderComponent } from "@mitel/cloudlink-console-components";
import { AppRoutingModule } from ".//app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardQueryComponent } from "./dashboard-query/dashboard-query.component";
import { ClLogService } from "@mitel/cloudlink-console-components";
import { WebpackTranslateLoader } from "./webpack-translate-loader";
import { IframeComponent } from "./iframe/iframe.component";
import { MatInputModule } from "@angular/material/input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";

@NgModule({
	declarations: [AppComponent, IframeComponent, DashboardQueryComponent],
	imports: [
		FormsModule,
		BrowserAnimationsModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatFormFieldModule,
		MatNativeDateModule,

		ClConsoleComponentsModule,
		NgbModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		MatProgressSpinnerModule,

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader,
				deps: [HttpClient]
			}
		}),
		ReactiveFormsModule,
		AppRoutingModule,
		BrowserAnimationsModule
	],
	exports: [MatInputModule],
	providers: [ClHeaderComponent, ClLogService],
	bootstrap: [AppComponent]
})
export class AppModule {}
