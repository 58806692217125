import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Config } from "@mitel/cloudlink-sdk";

import { AppModule } from "./app/app.module";
import { environment, DevRedirect, DevStageRedirect, PublicRedirect, PublicStageRedirect } from "./environments/environment";

Config.appInfo = environment.appInfo;

if (!environment.production || window.location.href.indexOf(".dev.") > 0 || window.location.href.indexOf("localhost") > 0) {
	Config.cloud = "dev";

	const devClientId =
		window.location.href.indexOf("localhost") > 0 ? "ef0129223a2e3bf76e7c3d8422b15b53" : "1f402d93d17244f7ad6f04abb8f0c927";
	environment.clientId = devClientId;
} else {
	Config.cloud = "public";
	environment.clientId = "50ae6c7461594683b8d8823bc88dd4a5";
}

if (window.location.href.indexOf("forcestage=true") > 0) {
	Config.stage = "stage";
} else {
	Config.stage = "live";
}

const isStage = window.location.href.indexOf("-stage") > 0;
if (Config.cloud === "dev") {
	environment.redirectUri = isStage ? DevStageRedirect : DevRedirect;
} else {
	environment.redirectUri = isStage ? PublicStageRedirect : PublicRedirect;
}

if (environment.production) {
	enableProdMode();
}

try {
	Config.authentication.whoAmI();
} catch {}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));
