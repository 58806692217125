import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { AuthenticationService as ClAuthenticationService } from "@mitel/cloudlink-sdk";
import { throwError, Subject, Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class GetDashboardService {
	authenticationService: ClAuthenticationService;
	dashboardsChanged = new Subject<any>();

	private dataDashboard: any;

	constructor(public http: HttpClient) {}
	urlDashboard = environment.urlDashboard;
	setDashboard(dashboardsCollection) {
		//Whenever the dashboards gets changed, changes can be pushed using next function
		this.dashboardsChanged.next(dashboardsCollection);
	}

	//Api call is made by getting the access token from local storage
	retrieveDashboard() {
		const headerDashboard = new HttpHeaders()
			.set("accept", "application/json")
			.set("Authorization", "Bearer " + localStorage.getItem("@mitel/token.access_token"));
		return this.http.get(environment.urlDashboard, { headers: headerDashboard }).pipe(catchError(this.erroHandler));
	}

	erroHandler(error: HttpErrorResponse) {
		return throwError(error);
	}
}
