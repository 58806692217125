import { Component, ChangeDetectorRef, Input } from "@angular/core";
@Component({
	selector: "app-iframe",
	templateUrl: "./iframe.component.html",
	styleUrls: ["./iframe.component.css"]
})
export class IframeComponent {
	data: any;
	constructor(public changeDetector: ChangeDetectorRef) {}
}
